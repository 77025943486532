import React, { useState, useEffect, useRef } from "react";
import "./ManageUser.css";
import HOC1 from "../../Hoc1.jsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions } from "@material-ui/core";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loder from "../../Loder/Loder.jsx";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ReactToPrint from "react-to-print";
import * as Service from "../../../services/index";
import { useApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { getData } from "../../../api/index";
import * as Constants from "../../../action/appAction";
import GenerateUser from "./GenerateUser";
import {
  blankValidator,
  getFullAddress,
  getFullName,
  getValue,
  showNotificationMsz,
} from "../../../utils/Validation";
import Holiday from "../../Common/Holiday/Holiday.jsx";
import UpdateParent from "./UpdateParent";

const ManageUserSetting = (props) => {
  let section = -1;
  if (props.location && props?.location?.state) {
    section = props?.location?.state?.section;
  }

  const ref = React.createRef();
  const componentRef = useRef();
  //----------------generate id card var---------------------
  const [userId, setUserId] = useState("");
  const [loginId, setLoginId] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  //----------------generate id card var---------------------
  const [value, setValue] = useState(0);
  const [searchById, setSearchById] = useState("");
  const [searchByLoginId, setSearchByLoginId] = useState("");

  const [isloading, setisloading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [GenerateIdCard, setGenerateIdCard] = useState(false);
  const [StudentsArr, setStudentsArr] = useState([]);
  const [page, setPage] = useState(0);
  const [imgageUrl, setImgageUrl] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();
  const [managegrade, setmanagegrade] = useState([]);
  const [gradeid, setgradeid] = useState(-1);

  const [sectionArr, setSectionArr] = useState([]);
  const [sectionid, setsectionid] = useState(-1);
  const [photo, setphoto] = useState("");

  const [address, setaddress] = useState(``);
  const [login_id, setlogin_id] = useState(``);
  const [name, setName] = useState(``);
  const [gradeandsection, setgradeandsection] = useState(``);
  // institution id for all api globally declared
  let academic_session_id = parseInt(props.data.academic_session_id);
  let institution_id = parseInt(props.data.institution_id);
  //name instituition

  const client = useApolloClient();
  useEffect(() => {
    getStudentList();
    getManageGrades();
  }, [props]);
  const getStudentList = async () => {
    try {
      setisloading(true);
      await client
        .query({
          query: Service.GET_Student_form_list,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          let temp = [];
          for (let i = 0; i < response.data.getStudentList.length; i++) {
            const element = response.data.getStudentList[i];
            if (element.student_profile && element.student_profile?.login) {
              temp.push(element);
            }
          }
          setStudentsArr(temp);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("catch err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const [search, setSearch] = useState("");
  const [searchName, setSearchName] = useState("");

  const handleChange = (event, newValue) => {
    setStudentsArr([]);
    if (newValue === 0) {
      getStudentList();
      getManageGrades();
    }
    setValue(newValue);
  };

  const changePasswordHandler = (loginid) => {
    console.log("loginid", loginid);
    let login_id = loginid;

    try {
      setisloading(true);
      client
        .query({
          query: Service.Change_User_Password_By_Admin,
          variables: {
            login_id,
          },
        })
        .then((response) => {
          setisloading(false);
          showNotificationMsz(
            response.data.changePasswordByAdmin.message,
            "success"
          );
        });
    } catch (error) {
      showNotificationMsz(error, "danger");
      setisloading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getManageGrades = () => {
    try {
      setisloading(true);
      client
        .query({
          query: Service.Get_Manage_Grade,
          variables: {
            academic_session_id,
          },
        })
        .then((response) => {
          setisloading(false);

          setmanagegrade(response.data.getGrade);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };

  const selectHandler = (id) => {
    let grade_id = parseInt(id);
    setgradeid(grade_id);
    setSectionArr([]);
    setsectionid(-1);
    if (parseInt(grade_id) == -1) {
      return;
    }
    try {
      setisloading(true);
      client
        .query({
          query: Service.GET_Section_Data,
          variables: {
            grade_id,
          },
        })
        .then((response) => {
          setSectionArr(response.data.getSectionAndGrade);
          setisloading(false);
        })
        .catch((e) => {
          setisloading(false);
          console.log("catch error", e);
        });
    } catch (error) {
      setisloading(false);
      console.log("t and c error", error);
    }
  };
  const searchBysection = (val) => {
    setsectionid(parseInt(val));
  };
  const handleIdCard = (params) => {
    setaddress(getFullAddress(props.data?.institute_address));
    setUserId(params?.student_profile?.login?.display_id);
    setName(getFullName(params.student_profile?.name));
    setphoto(`${params.student_profile?.photo?.path}`);
    setgradeandsection(
      `${params.section?.grade?.gradeName} ${params.section?.name}`
    );
    setGenerateIdCard(true);
  };
  const filterStudentArr = StudentsArr.filter((event) => {
    return (
      getFullName(event.student_profile.name)
        .toLowerCase()
        .indexOf(searchName.toLowerCase()) !== -1 &&
      getValue(event.student_profile.email)
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 &&
      getValue(event?.student_profile?.login.display_id)
        .toString()
        .toLowerCase()
        .indexOf(searchById.toLowerCase()) !== -1 &&
      getValue(event?.student_profile?.login.login_id)
        .toString()
        .toLowerCase()
        .indexOf(searchByLoginId.toLowerCase()) !== -1 &&
      (gradeid != -1
        ? blankValidator(event.section) && event.section.grade.id == gradeid
        : true) &&
      (sectionid != -1
        ? blankValidator(event.section) && event.section.id == sectionid
        : true)
    );
  });

  return (
    <div className="main_full_width">
      <div>
        <div className="margin_from_both_contact">
          <Grid className="grid_main_div">
            <Grid item md={12}>
              <Holiday name={props.data.institution_name} />

              <div className="enquirydetails_background mb-2">
                <div className="mt-2 ml-4 Heading_bold">Manage Login</div>

                <div className="card_margin_both_side">
                  <Card>
                    <div className="mt-2">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        left
                      >
                        <Tab
                          // label="Update Student"
                          label="Update Student"
                          {...a11yProps(0)}
                          className="button_login_decoration text_tranformation_exam"
                        />
                        <Tab
                          // label="Generate Student Login"
                          label="Generate Student/Parent Login"
                          {...a11yProps(1)}
                          className="button_login_decoration text_tranformation_exam"
                        />
                        <Tab
                          label="Update Parent"
                          {...a11yProps(2)}
                          className="button_login_decoration text_tranformation_exam"
                        />
                      </Tabs>
                    </div>
                    <div>
                      <TabPanel value={value} index={0}>
                        <div>
                          <div className="mt-4 mb-1 Heading_bold">
                            Search User
                          </div>
                          <Grid className="grid_main_div">
                            <Grid item md={2}>
                              <div className="mr-1 mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="ID"
                                  autoComplete="off"
                                  value={searchById}
                                  onChange={(e) => {
                                    setSearchById(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className="mr-1 mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Login ID"
                                  autoComplete="off"
                                  value={searchByLoginId}
                                  onChange={(e) => {
                                    setSearchByLoginId(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className="mr-1 mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  autoComplete="off"
                                  value={searchName}
                                  onChange={(e) => {
                                    setSearchName(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className="mr-1 mt-1">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email ID"
                                  autoComplete="off"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className="mr-1 mt-1">
                                <select
                                  className="form-control"
                                  value={gradeid}
                                  onChange={(e) =>
                                    selectHandler(e.target.value)
                                  }
                                >
                                  <option value={-1}>Search By Grade</option>

                                  {managegrade.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.gradeName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Grid>
                            <Grid item md={2}>
                              <div className="mr-1 mt-1">
                                <select
                                  class="form-control"
                                  onChange={(e) =>
                                    searchBysection(e.target.value)
                                  }
                                >
                                  <option value={-1}>Search By Section</option>
                                  {sectionArr.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Grid>
                          </Grid>

                          <div className="mt-4 mb-1 Heading_bold">
                            List Of Users
                          </div>

                          <div className="table_foramtitng mt-2">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      className="table_header"
                                      align="left"
                                    >
                                      ID
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Login ID
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Class
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Section
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Name
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Email
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Phone Number
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      className="table_header"
                                    >
                                      Actions
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="small-font">
                                  {(rowsPerPage > 0
                                    ? filterStudentArr.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                    : filterStudentArr
                                  ).map((row) => (
                                    <StyledTableRow>
                                      <StyledTableCell align="left">
                                        {
                                          row?.student_profile?.login
                                            ?.display_id
                                        }
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row?.student_profile?.login?.login_id}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.section?.grade?.gradeName}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {row.section?.name}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {getFullName(row.student_profile.name)}
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        {row.student_profile?.email}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {
                                          row.student_profile?.father
                                            ?.phone_number
                                        }
                                      </StyledTableCell>

                                      <StyledTableCell align="left">
                                        <span
                                          className="row_details_color pr-1 "
                                          onClick={() =>
                                            props.navigate(
                                              "/studentDetailForm",
                                              {
                                                state: {
                                                  studentId: row.id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          Open
                                        </span>
                                        |
                                        <span
                                          className="row_details_color pr-1 pl-1"
                                          onClick={() =>
                                            changePasswordHandler(
                                              row?.student_profile?.login
                                                ?.login_id
                                            )
                                          }
                                        >
                                          Reset Password
                                        </span>
                                        |
                                        <span
                                          className="row_details_color pl-1"
                                          onClick={() => handleIdCard(row)}
                                        >
                                          ID Card
                                        </span>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                true
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filterStudentArr.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableContainer>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <GenerateUser
                          ids={section}
                          academicId={props.data.academic_session_id}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <UpdateParent />
                      </TabPanel>
                    </div>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>

          <Dialog
            open={GenerateIdCard}
            onClose={() => {
              setGenerateIdCard(false);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogContent>
              <div className="id_card_border_padding" ref={componentRef}>
                <Grid className="grid_main_div">
                  {/* school name section */}
                  <div className="col-10" align="center">
                    <p className="schoolNameText">
                      <strong>{props.data?.institution_name}</strong>
                    </p>
                  </div>
                  <div className="col-11" align="center">
                    <p className="addressText">
                      {/* <strong>Address -</strong>  */}
                      {address}
                    </p>
                  </div>
                  <div className="col-2 mt-3 idCardImage_width">
                    <img
                      src={`${Service.baseUrl}/${photo}`}
                      className="student_id_photo_image"
                      alt="No_profile"
                    />
                  </div>
                  <div className="col-10  mt-3">
                    <div className="d-flex">
                      <div className="col-1">
                        <p> </p>
                        <p> </p>
                        <p> </p>
                      </div>
                      <div className="col-1">
                        <p> </p>
                        <p> </p>
                        <p> </p>
                      </div>
                      <div className="col-3">
                        <p className="student_name">ID</p>
                        <p className="student_name">Name</p>
                        <p className="student_name">Class</p>
                      </div>
                      <div className="col-8">
                        <p className="student_name">{userId}</p>
                        <p className="student_name">{name}</p>
                        <p className="student_name">{gradeandsection}</p>
                      </div>
                    </div>
                  </div>
                  {/* student section */}
                </Grid>
              </div>
            </DialogContent>

            <DialogActions className="idcard_button_center">
              <Button
                variant="contained"
                className="genrate_idcard_button_width"
                onClick={() => setGenerateIdCard(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="genrate_idcard_button_width"
              >
                <span>Print</span>
                <ReactToPrint
                  trigger={() => <i class="fa fa-print pl-2"></i>}
                  content={() => componentRef.current}
                />
                {/* Print */}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Loder loading={isloading} />
      </div>
    </div>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const mapStateToProps = (state) => ({
  data: getData(state, Constants.TOKEN),
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default connect(mapStateToProps, null)(HOC1(ManageUserSetting));
