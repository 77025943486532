import React, { useState, useEffect } from "react";
import Hoc6 from "../../Hoc6";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import "./Institution.css";
import Loder from "../../Loder/Loder.jsx";
import Dialog from "@material-ui/core/Dialog";
import {
  blankValidator,
  loadComponent,
  onScriptLoad,
  showNotificationMsz,
} from "../../../utils/Validation.js";
import { useMutation } from "@apollo/client";
//-----------------------redux--------------------------
import Holiday from "../../Common/Holiday/Holiday.jsx";
import { connect } from "react-redux";
import * as Constants from "../../../action/appAction";
import { getData } from "../../../api/index";
//-----------------------api calls--------------------------
import { useApolloClient } from "@apollo/client";
import {
  DELETE_INSTITUTION,
  GET_Institution_Details,
  Get_Academic_session,
  getGatWay,
} from "../../../services/index";

const config = require("../../../config/default.json");

const InstitutionSetup = ({ dispatch, UserInfo, navigate }) => {
  //-------------------------------global id---------------------------
  let organization_id = parseInt(UserInfo.organization_id);

  let organization_name = UserInfo.organization_name;
  //-------------------------------api calls---------------------------
  const client = useApolloClient();
  //-------------------------------local state---------------------------
  const [institutionarr, setInstitutionarr] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [opendialogConfimation, setopendialogConfimation] = useState(false);
  const [institutionName, setInstitutionName] = useState("");
  const [delete_institution, {}] = useMutation(DELETE_INSTITUTION);
  const [institutionDeleteId, setinstitutionDeleteId] = useState("");
  //-------------------------------methods---------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    const getInstitutionDetails = async () => {
      if (!blankValidator(organization_id)) {
        showNotificationMsz("Please Contact to Admin", "info");
      } else {
        try {
          setisloading(true);
          const { data } = await client.query({
            query: GET_Institution_Details,
            variables: {
              organization_id,
            },
          });

          if (data && data.institutions) {
            const { institutions } = data;
            if (!institutions || institutions.length < 0) {
              showNotificationMsz("There is no active session", "info");
            }
            if (UserInfo.type === "PT") {
              let institutefount = false;
              for (let index = 0; index < data.institutions.length; index++) {
                const element = data.institutions[index];
                if (element.id == UserInfo.institution_id) {
                  openManageInstitution(element);
                  institutefount = true;
                  break;
                }
              }
     //         if (institutefount == false)
       //         openManageInstitution(data.institutions[0]);
            }
            setInstitutionarr(data.institutions.reverse());
          }
          setisloading(false);
        } catch (error) {
          setisloading(false);
          console.log("Error", error);
        }
      }
    };

    getInstitutionDetails();
  }, [client, organization_id]);

  const openManageInstitution = async (Data) => {
    const institution_id = parseInt(Data.id);
    try {
      setisloading(true);
      const { data } = await client.query({
        query: getGatWay,
        variables: {
          institution_id,
          gateway_name: config.config.gateWayName,
        },
      });
      if (data && data.getGatWay) {
        const { getGatWay } = data;
        let url = `https://${config.config.paytm_hostname}/merchantpgpui/checkoutjs/merchants/${getGatWay.secret}.js`;
        const loaded = await loadComponent(url);
        console.log("loaded ", loaded);
        if (loaded) onScriptLoad();
      }

      await client
        .query({
          query: Get_Academic_session,
          variables: {
            institution_id,
          },
        })
        .then((response) => {
          if (response.data.getAcademicSession == null) {
            let temp = {
              data: Data,
              institution_name: Data.name,
              academic_session_id: null,
              institution_id: institution_id,
              institute_address: Data.address,
              session: "",
            };
            dispatch({
              type: Constants.USER_INFO,
              state: temp,
              key: Constants.TOKEN,
            });
            navigate("/homemenu");
          } else {
            let temp = {
              data: Data,
              institution_name: Data.name,
              academic_session_id: response.data.getAcademicSession.id,
              institution_id: institution_id,
              institute_address: Data.address,
              session:
                response.data.getAcademicSession.start_day.substring(0, 4) +
                "-" +
                response.data.getAcademicSession.end_day.substring(0, 4),
            };
            dispatch({
              type: Constants.USER_INFO,
              state: temp,
              key: Constants.TOKEN,
            });
            navigate("/homemenu");
          }
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log("err", err);
        });
    } catch (error) {
      setisloading(false);
      console.log("error", error);
    }
  };
  const openEditInstitution = (item) => {
    navigate("/createUpdateInstitution", {
      state: {
        pagetype: "edit",
        organization_id,
        data: item,
      },
    });
  };
  const deleteInstitution = (institutionData) => {
    const { id, name } = institutionData;
    setinstitutionDeleteId(id);
    setInstitutionName(name);
    setopendialogConfimation(true);
  };
  const removeInstitution = async () => {
    try {
      setisloading(true);
      const { data } = await delete_institution({
        variables: { id: +institutionDeleteId },
      });
      setisloading(false);
      showNotificationMsz(data.deleteInstitution.message, "success");
      setopendialogConfimation(false);
      // calling data to fetch
      if (!blankValidator(organization_id)) {
        showNotificationMsz("Please Contact to Admin", "info");
      } else {
        setisloading(true);
        client
          .query({
            query: GET_Institution_Details,
            variables: {
              organization_id,
            },
          })
          .then((response) => {
            if (
              !response.data.institutions ||
              response.data.institutions.length === 0
            ) {
              showNotificationMsz("There is no active session", "info");
            }
            setisloading(false);
            setInstitutionarr(response.data.institutions.reverse());
          })
          .catch((error) => {
            setisloading(false);
            showNotificationMsz(error, "danger");
          });
      }
    } catch (error) {
      showNotificationMsz({ message: "Something went wrong" });
      setisloading(false);
    }
  };
//  if (UserInfo.type === "PT") {
  //  return <></>;
 // } else
    return (
      <div className="main_full_width">
        <div>
          <div className="margin_from_both_contact">
            <Grid className="grid_main_div">
              <Grid item md={12}>
                <Holiday name={organization_name} isRemove={true} />
                <div className="enquirydetails_background mb-2">
                  <div className="enquiry_heading">
                    <div className="ml-3 mt-4 Heading_bold">Institutions</div>
                    <div className="button_main_flex invoice_btn_width mt-4">
                      <Button
                        variant="contained"
                        className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_size_invoice"
                        onClick={() =>
                          navigate("/createUpdateadmin", {
                            state: {
                              pagetype: "add",
                              name: organization_name,
                              organization_id,
                            },
                          })
                        }
                      >
                        Add New Institution
                      </Button>
                      <Button
                        variant="contained"
                        className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_size_invoice"
                        onClick={() =>
                          navigate("/adminSetup", {
                            state: {
                              adminData: organization_id,
                              pageType: "ADMIN",
                              orgenizationName: organization_name,
                            },
                          })
                        }
                      >
                        Manage Admin
                      </Button>
                    </div>
                    {/* <div className="button_main_flex invoice_btn_width mt-4">
                    <Button
                      variant="contained"
                      className="add_new_query_btn  button_width mr-2 button_login_decoration btn_font_size_invoice"
                      onClick={() =>
                        navigate("/createUpdateadmin", {
                          pagetype: "add",
                          name: organization_name,
                          organization_id,
                        })
                      }
                    >
                      Add Admin
                    </Button>
                    <Button
                      variant="contained"
                      className="add_new_query_btn button_width button_login_decoration btn_font_size_invoice mr-3"
                      onClick={() =>
                        navigate("/createUpdateInstitution", {
                          pagetype: "add",
                        })
                      }
                    >
                      Add New Institution
                    </Button>
                  </div> */}
                    {/* <div className="mr-3 mt-4 d-flex justify-content-between">
                    <Button
                      variant="contained"
                      className="btn_colr_and_width mr-2 mt-2 button_login_decoration"
                    >
                      Add Admin
                    </Button>
                    <Button
                      variant="contained"
                      className="btn_colr_and_width mr-2 mt-2 button_login_decoration"
                      onClick={() =>
                        navigate("/createUpdateInstitution", {
                          pagetype: "add",
                        })
                      }
                    >
                      Add New Institution
                    </Button>
                  </div> */}
                  </div>
                  <div className="card_margin_both_side">
                    <Card>
                      <div className="card_admissiondetails_height">
                        <div className="textfiled_margin">
                          {institutionarr.map((item, index) => (
                            <Card
                              className="cardvisible_border mb-2 mt-3"
                              key={index}
                            >
                              <div className="card_admissiondetails_height">
                                <div className="textfiled_margin">
                                  <div className="card_content_instition">
                                    <div>{item.name}</div>
                                    <div className="d-flex">
                                      <span
                                        className="mr-3 icon_pencil_color manage_edit_cursor"
                                        onClick={() =>
                                          openManageInstitution(item)
                                        }
                                      >
                                        Manage
                                      </span>
                                      <span
                                        className="icon_pencil_color manage_edit_cursor mr-3"
                                        onClick={() => {
                                          openEditInstitution(item);
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <span
                                        className="icon_pencil_color manage_edit_cursor"
                                        onClick={() => {
                                          deleteInstitution(item);
                                        }}
                                      >
                                        Remove
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Loder loading={isloading} />
        <Dialog
          open={opendialogConfimation}
          onClose={() => {
            setopendialogConfimation(false);
          }}
          aria-labelledby="draggable-dialog-title"
          className="dailog_formatting"
        >
          <div className="dailog_width_review_leave">
            <div>
              <div className="cut_dailog_icon float-right">
                <i
                  className="fa fa-times mr-3 cursor"
                  aria-hidden="true"
                  onClick={() => {
                    setopendialogConfimation(false);
                  }}
                ></i>
              </div>
            </div>
            <div className="pl-5 pr-5 mt-4">
              Are you sure want to delete the institution : {institutionName}
            </div>

            <Grid className="grid_main_div mt-2  mb-2 text-center">
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <div className="acceptation_btn">
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration mr-2"
                    onClick={removeInstitution}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    className="add_new_query_btn button_login_decoration"
                    onClick={() => {
                      setopendialogConfimation(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
};

const mapStateToProps = (state) => ({
  UserInfo: getData(state, Constants.TOKEN),
});

export default connect(mapStateToProps, null)(Hoc6(InstitutionSetup));
